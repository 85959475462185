/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Slideshow, Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kavárna MANETKA - Akce"} description={"Akce pořádané kavárnou a cukrárnou"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Slideshow style={{"backgroundColor":"rgba(34,25,77,0)","paddingLeft":0}} name={"bgrtewhx8a"} infinite={true} transitionDuration={0}>
          
          <Column className="css-1yhbzna js-anim  --anim4 --full" style={{"paddingBottom":0,"paddingTop":0}} fullscreen={true} anim={"4"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/9dc88415c11a4ac9b3a3077711c07c59_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingBottom":9,"paddingLeft":0,"paddingRight":0,"paddingTop":0}} columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":66,"backgroundColor":"rgba(62,11,95,0.46)","paddingLeft":67}}>
                
                <Title className="title-box title-box--left fs--102 title-box--invert" content={"<span style=\"color: var(--white);\">Hlednání velikonočního<br>pokladu!<br></span>"}>
                </Title>

                <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: var(--white); font-weight: bold;\">v sobotu 19. dubna 2025 od 15 hodin</span>"}>
                </Subtitle>

                <Text className="text-box text-box--left fs--36 text-box--invert" style={{"maxWidth":1209}} content={"Splň 10 soutěžních úkolů a najdi zajdu s pokladem!<br>Velikonoční speciality ;-)<br>"}>
                </Text>

                <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--left fs--22" style={{"backgroundColor":"var(--white)","marginTop":60,"marginBottom":14}} content={"<span style=\"color: rgb(88, 92, 99);\">Rezervace stolu zde</span><br>"} use={"page"} href={"/akce#rychla_rezervace"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="css-19iyzxj js-anim  --anim4 --full" style={{"paddingBottom":0,"paddingTop":0}} fullscreen={true} anim={"4"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/34102/e5b8cbac09e245209b9b5a11da8d4705_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" style={{"paddingBottom":9,"paddingLeft":0,"paddingRight":0,"paddingTop":0}} columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":71,"backgroundColor":"rgba(0,0,0,0.55)","paddingLeft":52}}>
                
                <Title className="title-box title-box--left fs--102 title-box--invert" content={"<span style=\"color: var(--white);\">PŘIPRAVUJEME:&nbsp;</span>"}>
                </Title>

                <Text className="text-box text-box--left fs--48 text-box--invert" style={{"maxWidth":827}} content={"Otevření zahrady<br>Velikonoce<br>Pálení čarodějnic a májku<br>"}>
                </Text>

                <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--left fs--22" style={{"backgroundColor":"var(--white)","marginTop":60,"marginBottom":14}} content={"<span style=\"color: rgb(88, 92, 99);\">sledujte naši stránku s akcemi ;-)</span><br>"} use={"page"} href={"/akce#bgrtewhx8a"}>
                </Button>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column style={{"backgroundColor":"rgba(135,140,149,1)","paddingBottom":78,"paddingTop":2,"marginBottom":0}} name={"spolupracujeme"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800,"paddingTop":47}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Spolupracujeme...</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--white);\">Kudy z nudy</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Nově spolupracujeme s portálem Kudy z nudy, kde naleznete projekt České centrály cestovního ruchu – CzechTourism, jehož cílem je podpora domácího cestovního ruchu. Kudy z nudy nabízí komplexní nabídku pro každého cestovatele.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: rgb(104, 102, 108);\">Kudy z nudy</span>"} url={"https://www.kudyznudy.cz/"} href={"https://www.kudyznudy.cz/"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1orm3ur css-42e4bw --parallax pb--80 pt--80" name={"rychla_rezervace"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/34102/92095680b9ff4161a906a73f6221815a_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --shape4 el--1 pb--20 pt--20" style={{"backgroundColor":"rgba(0,0,0,0.6)","paddingLeft":67}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":900}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Rychlá rezervace</span>"}>
              </Title>

              <Title className="title-box title-box--left fs--43 w--400 ls--02 lh--16 pb--12 pt--16" content={"<span style=\"color: white;\">kavárna a cukrárna MANETKA<br><a href=\"tel:+420773300833\">+420 773 300 833</a><br><a href=\"mailto:kavarna@manetka.cz?subject=Rezerva%C4%8Dn%C3%AD+formul%C3%A1%C5%99+z+webu\" target=\"_blank\">kavarna@manetka.cz</a><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"rgba(135,140,149,1)","paddingTop":83,"paddingBottom":80}} name={"galerieakci"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center ff--2 fs--86 w--400" content={"<span style=\"color: var(--white);\">Galerie akcí</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--0 pt--0">
              
              <Text className="text-box text-box--center fs--26" style={{"maxWidth":664}} content={"<span style=\"color: var(--white);\">Prohlédněte si galerii proběhlých akcí. Tohle vše u nás můžete zažít! Stačí sledovat naše webové stránky <br>nebo sociální sítě ;-)</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":"","paddingBottom":0,"marginTop":63,"paddingTop":0,"marginBottom":0}} columns={"4"}>
            
            <ColumnWrapper className="--hvr5 --left" style={{"maxWidth":291}}>
            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/af6200eac712419484ac37527bd03a25_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/af6200eac712419484ac37527bd03a25_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/af6200eac712419484ac37527bd03a25_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/af6200eac712419484ac37527bd03a25_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/b274e6e5d60146ff98ae2ad769c96b15_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/b274e6e5d60146ff98ae2ad769c96b15_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/b274e6e5d60146ff98ae2ad769c96b15_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/b274e6e5d60146ff98ae2ad769c96b15_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/e5ccf6377dd64f048a7fe782f8e46863_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/9041f429232448a1a434659f387f8a06_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/e3384051cb5c4d4ebdac7c7e125fea66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/e3384051cb5c4d4ebdac7c7e125fea66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/e3384051cb5c4d4ebdac7c7e125fea66_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/e3384051cb5c4d4ebdac7c7e125fea66_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/34102/e3384051cb5c4d4ebdac7c7e125fea66_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/7c238566668d432ea75ce03945be8f34_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/7c238566668d432ea75ce03945be8f34_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/7c238566668d432ea75ce03945be8f34_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/7c238566668d432ea75ce03945be8f34_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/9e25787c720a4fa68a2e50c3d8f3b71e_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 flex--top" style={{"maxWidth":"","paddingBottom":0,"marginTop":35,"paddingTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--hvr5 --left" style={{"maxWidth":291}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/ef58e52b449a4669b1c56654ef4d0e86_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5 --left">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=660x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/125ea890b1f8487b822bfc5011374146_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/34102/c009d08e5cbe41a7ab3de48ac2a435ba_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":306}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/34102/f1c8d8ed373e427d829f3b7e99977a8d_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mt--50 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="mt--0 pt--0">
              
              <Button className="btn-box btn-box--hvr4 btn-box--shape5 btn-box--cColor1 btn-box--shadow5" style={{"backgroundColor":"var(--white)"}} content={"<span style=\"color: var(--color-custom-1--15);\">Celá galerie akcí</span>"} use={"page"} href={"/fotogalerie#foto_akce"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":104,"paddingBottom":103}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1406,"paddingLeft":0,"marginBottom":0}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">WEB</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"marginTop":14,"marginBottom":0,"paddingBottom":30}} content={"<span style=\"color: var(--black);\"><span style=\"text-decoration-line: underline;\"><a href=\"/#bcckfdbfejb\" style=\"\">O nás</a></span><a href=\"/menu#menu_uvod\"><br>Stálé menu<br></a><a href=\"/akce#j40igf0u8gp\">Akce</a><a href=\"/menu#menu_uvod\" style=\"\"><br></a><a href=\"/salonek#salonek_uvod\" style=\"\">Salónek</a><a href=\"/menu#menu_uvod\"><br></a><a href=\"/kontakt#kontakty\" style=\"\">Kontakt</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">KAVÁRNA A CUKRÁRNA MANETKA</span>"}>
              </Title>

              <Text className="text-box fs--18 w--400 ls--002 lh--16" style={{"maxWidth":396,"marginTop":13,"paddingRight":0,"paddingBottom":0}} content={"<span style=\"color: var(--black);\">Manětínská 30, Plzeň<br>tel: 773 300 833, email: kavarna@manetka.cz</span><br><span style=\"text-decoration-line: underline; font-weight: bold; color: var(--black);\"><a href=\"https://www.google.com/maps/place/Manetka+cafe/@49.7800782,13.3664327,3a,90y,117.55h,89.06t/data=!3m7!1e1!3m5!1sAF1QipPbAylCirgJw9XZmzsz7O3ipPU8vZNmJViK7vnr!2e10!3e12!7i11000!8i5500!4m9!3m8!1s0x470af33bb34100a5:0x17c49104135d9431!8m2!3d49.7798029!4d13.3665938!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11l2m45152?entry=ttu\" target=\"_blank\" style=\"\">VIRTUÁLNÍ PROHLÍDKA KAVÁRNY</a></span><br><br><span style=\"font-weight: bold;\">OTEVÍRACÍ DOBA KAVÁRNY</span><br>Po - Čt&nbsp; 09:00 - 20:00, Pá 09:00 - 21:00<br>So 12:00 - 21:00, Ne&nbsp;12:00 - 20:00<br>"}>
              </Text>

              <Image style={{"maxWidth":124,"marginTop":33}} alt={""} src={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png"} use={"page"} href={"/#bcckfdbfejb"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=350x_.png 350w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=660x_.png 660w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=860x_.png 860w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=1400x_.png 1400w, https://cdn.swbpg.com/t/34102/2706bcfce46e4dc5857125e2696c040e_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--20 w--400 ls--002 lh--14" content={"<span style=\"font-weight: bold; color: var(--black);\">SLEDUJ NÁS</span>"}>
              </Title>

              <Image style={{"maxWidth":64,"marginTop":31}} alt={"Facebook"} src={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png"} url={"https://www.facebook.com/Manetkacafe"} use={"url"} href={"https://www.facebook.com/Manetkacafe"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/556bd886db6346cc9a4b6ae94cde69b1_s=350x_.png 350w"} lightbox={false}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":35}} alt={"Instagram"} src={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png"} url={"https://www.instagram.com/manetka_cafe/"} use={"url"} href={"https://www.instagram.com/manetka_cafe/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/34102/9fa843421abe4a449de7c1ad3493add4_s=350x_.png 350w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}